<template>
  <div>
    <div class="item-adds" v-for="add in adds" :key="add.id">
      <object-item-add :title="add.key" :value="add.value" />
    </div>
  </div>
</template>

<script>
import ObjectItemAdd from "@/components/Parts/ObjectItemAdd";

export default {
  props: ["adds"],
  components: { ObjectItemAdd },
};
</script>