<template>
  <div class="swiper-row">
    <div class="button-next" :class="'uid'+_uid" v-show="length>2">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path
          fill="#59a8fb"
          fill-rule="nonzero"
          d="M34.354 25.736l-17.16 17.121-3.48-3.471L29.09 24 13.714 8.614l3.48-3.471 17.16 17.121L36 24z"
        />
      </svg>
    </div>
    <swiper :options="swiperOptions" @slideChange="slideChange()">
      <slot />
    </swiper>
  </div>
</template>

<script>
export default {
  name: "SwiperRow",
  props: {
    length: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        touchRatio: 2,
        navigation: {
          nextEl: ".button-next.uid" + this._uid
        }
      }
    };
  },
  methods: {
    slideChange() {
      setTimeout(() => {
        document
          .querySelector(".button-next.uid" + this._uid)
          .classList.add("hide");
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.swiper-row {
  width: 100%;
  position: relative;
  .button-next {
    position: absolute;
    right: -174px;
    top: 9px;
    z-index: 10;
    width: 256px;
    height: 256px;
    background: $color_grey_1;
    opacity: 0.8;
    border-radius: 50%;
    svg {
      position: absolute;
      top: 104px;
      left: 24px;
    }
    &.hide {
      display: none;
    }
  }
}
</style>