<template>
  <div>
    <div class="shade-scroll" v-if="showShade"></div>
    <div class="content" @scroll="onScroll">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScrolledContent",
  data() {
    return {
      showShade: false
    };
  },
  methods: {
    onScroll() {
      this.showShade = event.target.scrollTop > 0 ? true : false;
    }
  }
};
</script>


<style lang="scss" scoped>
.shade-scroll {
  position: relative;
  z-index: 10;
  content: "";
  width: 488px;
  height: 2px;
  box-shadow: 0 2px 4px 0 rgba(20, 20, 20, 0.8);
  background-color: #242423;
  // background: red;
}

.content {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  text-align: left;
}
</style>