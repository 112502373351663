<template>
  <div class="item-add">
    <div class="icon" v-if="icon">
      <date-time-icon
        v-if="icon === 'date'"
      />

      <place-marker-icon 
        v-if="icon === 'place'"
      />

      <web-icon 
        v-if="icon === 'web'"
      />

      <ticket-icon 
        v-if="icon === 'ticket'"
      />
    </div>

    
    <div class="text">
      <span v-if="title">
        {{ title.charAt(0).toUpperCase() + title.slice(1) }}:
        <br />
      </span>
      <span 
        v-if="value"
        :class="{ 'regular': title }"
      >
        {{ value }}
      </span>
    </div>
  </div>
</template>

<script>
import DateTimeIcon from "@/components/Icons/DateTime";
import PhoneIcon from "@/components/Icons/Phone";
import PlaceMarkerIcon from "@/components/Icons/PlaceMarker";
import WebIcon from "@/components/Icons/Web";
import TicketIcon from "@/components/Icons/Ticket";

export default {
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      value: ""
    }
  },
  components: {
    DateTimeIcon,
    PhoneIcon,
    PlaceMarkerIcon,
    WebIcon,
    TicketIcon
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.item-add {
  display: flex;
  margin-bottom: 16px;

  .icon {
    margin-top: -3px;
    margin-right: 8px;
  }

  .text {
    font-family: $ObjectSans;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: rgba(#fff, 0.97);
    .regular {
      font-weight: normal;
    }
  }
}
</style>